class ElementToLeaveObserver {
    observer = null;
    constructor() {}
  
    init(element, callback) {
      this.observer = new IntersectionObserver(
        function (entries) {
          entries.forEach(function (entry) {
            // 如果元素的交叉比例为0，表示它完全离开了视口
            if (entry.intersectionRatio === 0) {
              // console.log("元素离开了视口");
              // 在这里触发你的指定事件
              callback.call(this)
            }
          });
        },
        {
          // 配置选项
          root: null, // 指定一个元素作为根节点，null表示文档的视窗
          rootMargin: "0px", // 根节点的边界外的额外区域
          threshold: 0, // 触发回调的交叉比例阈值，0表示元素完全离开视口
        }
      );
      this.observer.observe(element);
    }
    unobserve(element) {
      this.observer.unobserve(element);
    }
    disconnect() {
      this.observer.disconnect();
    }
  }
  
  export default new ElementToLeaveObserver();