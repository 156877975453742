import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gongyi',
    name: 'gongyi',
    component: () => import('../views/GongYi.vue')
  },
  {
    path: '/ziben',
    name: 'ziben',
    component: () => import('../views/ZiBen.vue')
  },
  {
    path: '/yihuo',
    name: 'yihuo',
    component: () => import('../views/YiHuo.vue')
  },
  {
    path: '/zhengjing',
    name: 'zhengjing',
    component: () => import('../views/ZhengJing.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import( '../views/ContactView.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    component: () => import( '../views/RecruitView.vue')
  }, 
  {
    path: '/companyIntroduce',
    name: 'companyIntroduce',
    component: () => import('../views/CompanyIntroduce.vue')
  },
  {
    path: '/viewMsg',
    name: 'viewMsg',
    component: () => import('../views/viewMsg.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUsView.vue'),
    children: [
      {
        path: 'companyIntroduce', // /admin/user/list
        component: () => import('../components/CompanyIntroduce.vue'),
        meta: {
          title: '公司介绍'
        }
      }
    ]
  },
  {
    path: '/businessIntroduce',
    name: 'businessIntroduce',
    component: () => import('../views/BusinessIntroduction.vue'),
    children: [
      {
        path: 'jingyihudong',
        component: () => import('../components/Jingyihudong.vue'),
        meta: {
          title: '鲸益互动'
        }
      },
      {
        path: 'mujingyihuo', // /admin/user/add-new
        component: () => import('../components/Mujingyihuo.vue'),
        meta: {
          title: '牧鲸易货'
        }
      },
      {
        path: 'chaojilihe', // /admin/user/add-new
        component: () => import('../components/Chaojilihe.vue'),
        meta: {
          title: '超级鲤盒'
        }
      },
      {
        path: '188jiuwang', // /admin/user/add-new
        component: () => import('../components/188jiuwang.vue'),
        meta: {
          title: '188酒网'
        },
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
