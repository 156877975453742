<template>
  <div class="home" :style="'margin-top : ' + bannerMarginTop + 'px;'">
    <!-- 轮播图 -->
    <el-carousel class="carrousel" id="el-carrousel">
      <el-carousel-item v-for="(item,index) in imgBox" :key="index">
        <img :src="item.url" alt="" :style="'height: ' + bannerHeight + 'px;'">
      </el-carousel-item>
    </el-carousel>

    <!-- 业务体系 -->
    <div class="business" :style="'padding-top: ' + businessPaddingTop + 'px;'">
      <div class="business-title">
        <div class="business-title-name" :style="mobile_flag ? 'font-size: 16px;' : 'font-size: 33px;'">业务体系</div>
        <div class="business-title-second-title" :style="mobile_flag ? 'font-size: 10px;' : 'font-size: 21px;'">牧鲸集团是一家以媒体为基础的传媒和产发集团旗下拥有四大业务板块</div>
      </div>
      <div class="business-content">
        <div class="business-content-list" v-for="(item,index) in buinessList" :key="index" :style="mobile_flag ? 'padding-top: 39px;' : 'padding-top: 79px;'">
          <div class="business-content-item" :style="mobile_flag ? 'height: 50px;' : 'height: 100px;'">
            <div class="business-content-item-left" :style="mobile_flag ? 'left: 15%;' : 'left: 33%;'">
              <img :src="item.pic" alt="" :style="mobile_flag ? 'height: 35px;' : 'height: 71px;margin: 13px auto;'" />
            </div>
            <div class="bussiness-content-item-right" :style="mobile_flag ? 'height: 40px;' : 'height: 98px;'">
              <div class="bussiness-content-item-right-text" v-for="(textItem, textIndex) in item.content" :key="textIndex">
                <div class="bussiness-content-item-right-text-more" v-if="item.content.length > 1" :style="mobile_flag ? 'margin: 0 auto;padding-top: 0px;font-size: 10px;' : 'padding-top: 20px;'">{{ textItem.value }}</div>
                <div class="bussiness-content-item-right-text-one" v-if="item.content.length == 1" :style="mobile_flag ? 'margin: 0 auto;font-size: 10px;' : 'margin: 40px auto;'">{{ textItem.value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- banner图，只有一张 -->
    <div class="banner-2" :style="mobile_flag ? 'padding-top: 50px;' : 'padding-top: 228px;'">
      <div class="banner-2-img">
        <img :src="'https://mejour-cn.oss-cn-beijing.aliyuncs.com/banner/banner2@2x.png'" alt="" style="width: 100%;">
      </div>
    </div>

    <!-- 媒体资源 -->
    <div class="business" :style="'padding-top: ' + videoPaddingTop + 'px;'">
      <div class="business-title">
        <div class="business-title-name" :style="mobile_flag ? 'font-size: 16px;' : 'font-size: 33px;'">媒体资源</div>
        <div class="business-title-second-title" :style="mobile_flag ? 'font-size: 10px;' : 'font-size: 21px;max-width: 663px;width: 663px;'">含商圈、出行、社区、线上等媒体资源于一体的媒体营销生态圈</div>
      </div>
      <div :style="'height:auto; margin: 0 auto;overflow: hidden;padding: 12px;max-width: ' + (mobile_flag ? 340 : 1440) + 'px;padding-top: ' + (mobile_flag ? 5 : 83) + 'px;'">
        <div :class="item.class" v-for="(item, index) in caseList" :key="index" :index="index" :style="'height: ' + (item.height + 20) + 'px;width: ' + item.width + 'px;max-width: 322px;' + (mobile_flag ? 'max-height: 40px;' : 'max-height: 178px;') + (mobile_flag ? 'padding-top: 25px;' : (index < 4 ? 'padding-top: 0px;' : ''))">
          <div>
            <img :style="'height: ' + item.height + 'px;width: ' + item.width + 'px;max-width: 322px;max-height: 138px'" :src="item.src" alt="">
          </div>
          <div class="business-case-name" :style="mobile_flag ? 'font-size: 7px;line-height: 20px;' : ''">{{ item.name }}</div>
        </div>
      </div>
    </div>


    <!-- 合作客户 -->
    <div class="clients" :style="'padding-top: ' + clientsPaddingTop + 'px;'">
      <div class="business-title">
        <div class="business-title-name" :style="mobile_flag ? 'font-size: 16px;' : 'font-size: 33px;'">合作客户</div>
        <div class="business-title-second-title" :style="mobile_flag ? 'font-size: 10px' : 'font-size: 21px;max-width: 400px'">真诚的对待每一位客户带来更好的服务体验</div>
      </div>
      <div class="clients-content" :style="mobile_flag ? 'padding-top: 40px;max-width: 375px' : 'padding-top: 90px;'">
        <img alt="" :src="clientsPic" :style="mobile_flag ? 'width: 350px;' : 'width: 1217px;'" />
      </div>
    </div>
  </div>
</template>

<style>
.group-size img{
  width: 100%;
}
.el-carousel__container{
  height: 100% !important;
}
.home .carrousel{
  max-height: 1133px;
  height: 100%;
}
.el-carousel__item img{
  width: 100%;
}
.case1{
  width: 322px;
  height: 138px;
  float: left;
  margin-bottom: 10px;
  padding-top: 36px;
  margin: 0 10px;
}
.case2{
  width: 322px;
  height: 138px;
  background-color: #f3f3f3;
  float: right;
  margin-bottom: 10px;
}
.home{
  margin:0 auto;
  width: 100%;
  height: auto;
  /* position: absolute;
  top: 0; */
}
.carrousel{
  position: relative;
  margin:0 auto ;
  color: black;
  max-width: 100%;
  text-align: center;
}
#firstTitle{
  margin-top: 150px;
  position: absolute;
  color: #0c1752;
  font-size: 40px;
  opacity: 0.75;
  z-index: 10;
  width: 100%;
}
#secondTitle{
  margin-top: 200px;
  width: 100%;
  position: absolute;
  color: #0c1752;
  font-size: 20px;
  opacity: 0.75;
  z-index: 10;
}


.wholeProject{
  max-width:1200px;height: auto;margin: 0 auto;overflow: hidden;
  position: absolute;
}
.projectFour{
  max-width: 300px;
  float: left;

}
/*以下*/
.img-container {
  position: relative;
  max-width: 300px;
  text-align: center;
}
.img-container:after{
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  /*background: #191919;*/
}
.projectLogo{
  position: absolute;z-index: 10

}
.shortIntroduce span {
  padding: 4px;
  margin: 0 40px;
  text-align: center;
}
.business{
  margin: 10px;
  position: relative;
}
.business-title{
}
.business-title-name{
  font-family: AlibabaPuHuiTiB;
  font-size: 33px;
  font-weight: bolder;
  text-align: center;
  color: #FFC200;
  font-weight: bold;
  height: 31px;
}
.business-title-second-title{
  font-family: AlibabaPuHuiTiR;
  color: #FFFFFF; 
  margin: 0.5% auto;
  text-align: center;
  width: 495px;
  max-width: 375px;
  font-size: 21px;
  font-weight: 400;
}
.business-content-first{
  display: flex;
}
.business-content-detail{
  text-align: center;
  margin: 10px;
  border: 1px solid black;
  width: 50%;
  height: 100px;
  position: relative;
}
.business-content-detail-title{
  font-weight: bold;
}
.business-content-detail-msg{
  margin: 10px auto;
  position: absolute;
  width: 100%;
  top: 12%;
}
.business-content-detail-content{
  padding-top: 10px;
  color: #959090; 
  margin: 0.5% auto;
  width:auto;
  font-size: 8px;
  text-align: center;
  margin: 0 5px;
}
.banner-2{
  margin: 0 auto;
  width: 90%;
  padding-top: 10px;
}
.banner-2-img{
  margin: 10px auto;
}
.clients{
  padding-top: 10px;
  margin: 10px auto 30px;
  width: 100%;
}
.clients-title-second{
  padding-top: 10px;
  color: #959090; 
  margin: 0.5% auto;
  width:auto;
  font-size: 8px;
  text-align: center;
  margin: 0 5px;
}
.clients-title-first{
  font-size: 1.5rem;
  font-weight: bolder;
  text-align: center;
}
.clients-content{
  /* width: 90%; */
  margin: 10px auto;
  padding-top: 90px;
  text-align: center;
}
.clients-content-list{
  margin: 0px auto;
  height: auto;
  overflow: hidden;
  text-align: center;
}
.clients-content-item{
  /* border: 1px solid black; */
  width: 100px;
  display: -webkit-inline-box;
  margin: 10px;
  text-align: center;
}

.business-content-list:first-child{
  padding-top: 58px;
}
.business-content-list{
  padding-top: 79px;
}
.business-content-item{
  display: inline-flex;
  /* position: relative; */
  height: 100px;
}
.business-content-item-left{
  position: absolute;
  left: 33%;
}
.business-content-item-left img{
  height: 71px;
}
.bussiness-content-item-right{
  position: absolute;
  left: 50%;
  border-left: 1px solid #FFFFFF;
  height: 98px;
  color: #FFFFFF;
}
.bussiness-content-item-right-text{
  padding-left: 29px;
  position: relative;
}
.bussiness-content-item-right-text-one{
  margin: 40px auto;
  color: #FFFFFF;
  font-size: 17px;
  font-family: AlibabaPuHuiTiL;
}
.bussiness-content-item-right-text-more{
  padding-top: 20px;
  color: #FFFFFF;
  font-size: 17px;
  font-family: AlibabaPuHuiTiL;
}
.business-case-name{
  text-decoration: underline;
  color: #FFFFFF;
  line-height: 40px;
  font-size: 18px;
  font-family: AlibabaPuHuiTiL;
}

</style>
<script>

export default {
  name: 'HomeView',
  data() {
    return {
      title1:'不做时代中的随波者，争当洪流里的牧鲸人',
      title2:'',
      imgBox:[
        {id:1,url: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/banner/banner.png' },
        {id:2,url: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/banner/banner2.png' },
      ],
      bannerHeight: 1133,
      screenWidth: null,
      bannerHeightGroup: 155,
      mobile_flag: false,
      businessIntroduceList: [
        {
          src: require("../assets/old/project/01超级鲤盒.png"),
          url: "/businessIntroduce/chaojilihe",
          width: 300,
          height: 300,
        },
        {
          src: require("../assets/old/project/02鲸益互动.png"),
          url: "/businessIntroduce/jingyihudong",
          width: 300,
          height: 300,
        },
        {
          src: require("../assets/old/project/03牧鲸易货.png"),
          url: "/businessIntroduce/mujingyihuo",
          width: 300,
          height: 300,
        },
        {
          src: require("../assets/old/project/04188酒网.png"),
          url: "/businessIntroduce/188jiuwang",
          width: 300,
          height: 300,
        },
      ],
      caseList: [
        {
          class: 'case1',
          src: require("../assets/image/高铁媒体@2x.png"),
          width: 322,
          height: 138,
          name: '高铁媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/地铁媒体@2x.png"),
          width: 322,
          height: 138,
          name: '地铁媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/影院媒体@2x.png"),
          width: 322,
          height: 138,
          name: '影院媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/社区媒体@2x.png"),
          width: 322,
          height: 138,
          name: '社区媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/商圈媒体@2x.png"),
          width: 322,
          height: 138,
          name: '商圈媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/梯媒媒体@2x.png"),
          width: 322,
          height: 138,
          name: '梯媒媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/机场媒体@2x.png"),
          width: 322,
          height: 138,
          name: '机场媒体',
        },
        {
          class: 'case1',
          src: require("../assets/image/线上媒体@2x.png"),
          width: 322,
          height: 138,
          name: '线上媒体',
        },
      ],
      fontSize: 1.5,
      groupHeight: 0,
      clientsWidth: 288,
      bannerMarginTop: -170,
      businessPaddingTop: 171,
      buinessList: [
        {
          pic: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com//image/正鲸@2x.png',
          content: [
            {
              value: '以创意、内容、技术驱动的数字营销集团'
            },
          ],
        },
        {
          pic: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/image/易货@2x.png',
          content: [
            {
              value: '一家以货品结算媒体费用'
            },
            {
              value: '年去化产品超10亿的新传媒机构'
            },
          ],
        },
        {
          pic: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/image/资本@2x.png',
          content: [
            {
              value: '专注媒体深耕消费，一体两翼战略'
            },
            {
              value: '投资媒体同时以媒体资源投资品牌方'
            },
          ],
        },
        {
          pic: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/image/公益@2x.png',
          content: [
            {
              value: '致力于用媒体更好传播公益广告'
            },
            {
              value: '努力为品牌方与集团贡献社会价值'
            },
          ],
        },
      ],
      videoPaddingTop: 333,
      clientsPaddingTop: 177,
      clientsPic: 'https://mejour-cn.oss-cn-beijing.aliyuncs.com/image/logos.png',
    }
  },
  mounted() {
    this.isMobile();
    this.screenWidth = window.screen.width;
    
    this.bannerMarginTop = -170
    this.businessPaddingTop = 171
    this.videoPaddingTop = 333
    this.clientsPaddingTop = 177
    if (this.mobile_flag) {
      this.bannerMarginTop = -45
      this.businessPaddingTop = 40
      this.videoPaddingTop = 50
      this.clientsPaddingTop = 70
    }
    // if(!this.mobile_flag){
    //   this.screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // }else{
    //   var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //   var a = window.localStorage.getItem("screenWidth")
    //   if(!a){
    //     this.screenWidth = width;
    //     window.localStorage.setItem("screenWidth", width)
    //   }else{
    //     this.screenWidth = a;
    //   }
    // }
    this.setSize1();
    this.setSizeGroup1();
    // this.setSizeBusinessArea1();
    this.setSizeBusinessAreaImg1();
    this.setSizeCase1();
    this.setSizeClients();
    // const that = this;
    //监听浏览器窗口大小改变
    // window.addEventListener('resize', function() {
    //   var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //   that.screenWidth = width;
    //   that.setSize();
    //   that.setSizeGroup();
    //   that.setSizeBusinessArea();
    //   that.setSizeBusinessAreaImg();
    //   that.setSizeCase1();
    // }, false);
  },
  methods:{
    setSizeClients(){
      var width = (window.outerWidth * 0.9 - 120) / 6
      if (!this.mobile_flag) {
        width = width
      }
      this.clientsWidth = width;
    },
    isMobile() {
      var userAgentInfo = navigator.userAgent;
      var mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var mobile_flag = false;
      //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
          if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                mobile_flag = true;
                break;
          }
        }
        var screen_width = window.screen.width;
        var screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
          mobile_flag = true;
      }
      this.mobile_flag = mobile_flag
      if(mobile_flag){
        this.fontSize = 0.8
      }
    },
    setSize1: function() {
      //图片                高 / 宽  753 / 1920
      var height1 = 1133 /(1920/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      document.getElementById('el-carrousel').style.height = height1 + 'px';
      this.bannerHeight = height1;
    },
    setSize: function() {
      this.bannerHeight = 1133 / 1920 * this.screenWidth - 0
      var height1 = 1133 /(1920/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      document.getElementById('el-carrousel').style.height = height1 + 'px';
      this.bannerHeight = height1;
    },
    setSizeGroup1: function() {
      this.bannerHeightGroup = 155 / 1202 * this.screenWidth - 0
      var height1 = 155/(1202/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      // document.getElementById('group-size').style.height = height1 + 'px';
      this.groupHeight = height1;
    },
    setSizeGroup: function() {
      this.bannerHeightGroup = 155 / 1202 * this.screenWidth - 0
      var height1 = 155/(1202/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      // document.getElementById('group-size').style.height = height1 + 'px';
      this.groupHeight = height1;
    },
    setSizeBusinessArea1: function() {
      this.bannerHeightGroup = 340 / 1200 * this.screenWidth - 0
      var height1 = 340/(1200/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      document.getElementById('business-area').style.height = height1 + 'px';
    },
    setSizeBusinessArea: function() {
      this.bannerHeightGroup = 340 / 1200 * this.screenWidth - 0
      var height1 = 340/(1200/this.screenWidth)
      if(this.mobile_flag){
        height1 = height1
      }
      document.getElementById('business-area').style.height = height1 + 'px';
    },
    setSizeBusinessAreaImg1: function() {
      var screen_width = window.screen.width;
      var height1 = screen_width/4
      if(this.mobile_flag){
        height1 = height1
      }
      this.businessIntroduceList.forEach(item => {
        item.width = height1;
        item.height = height1;
      })
    },
    setSizeBusinessAreaImg: function() {
      var screen_width = window.screen.width;
      var height1 = screen_width/4
      if(this.mobile_flag){
        height1 = height1
      }
      this.businessIntroduceList.forEach(item => {
        item.width = height1;
        item.height = height1;
      })
    },
    setSizeCase1: function() {
      var height1 = 138/(322/window.screen.width)
      var width = 322 / (322 / window.screen.width)
      if(this.mobile_flag){
        height1 = 138 / (322 / 65)
        width = 65
      }
      this.caseList.forEach(item => {
        item.width = width;
        item.height = height1;
      })
    },
  },
  components: {

  }
}
</script>
