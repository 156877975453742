<template>
  <div class="img-box" :style="mobile_flag ? 'padding: 0 31px;' : 'padding: 0 71px;'">
    <img :src="bannerPic" alt="图片显示异常" />
  </div>
</template>

<script>
export default {
  name: "ImageMsg",
  props: {
    bannerPic: {
      type: String,
      default: ""
    },
    mobile_flag: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
.img-box {
    width: 70%;
    overflow: hidden;
    margin: 0 auto;
    
}
.img-box > img {
    width: 200%;
    transform: translateX(-25%);
    margin-top: .625rem;
}
</style>
